import { Maybe } from 'graphql/jsutils/Maybe'
import { RecursivePartial } from 'shared/presenters/presenter'

const getRentalDisplayPrice = ({
  minimumDuration,
  durationMins,
  lowestResourceReqPrice
}: {
  minimumDuration: RecursivePartial<Maybe<number>>
  durationMins: RecursivePartial<Maybe<number>>
  lowestResourceReqPrice: number
}) => {
  if (minimumDuration && durationMins) {
    return Math.ceil(minimumDuration / durationMins) * lowestResourceReqPrice
  } else {
    return lowestResourceReqPrice
  }
}

export default getRentalDisplayPrice
