import PurchasableCardPrice from 'library/molecules/PurchasableCardPrice'
import Link from 'next/link'
import CoverImage from 'library/atoms/CoverImage'
import { definedNotNull } from 'shared/presenters/presenter'
import widgetRoutes from 'enums/routes'
import { Text, H4 } from 'library/atoms/Typography'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import Item, { ItemText, ItemBottom } from 'library/molecules/Item'
import {
  SchedulableCardProps,
  StyledTagsList,
  StyledDivider,
  StyledTag,
  StyledH5
} from 'library/molecules/SchedulableCard'
import LocationTag from 'library/molecules/LocationTag'
import styled from '@emotion/styled'

const ActivityCard = ({
  schedulable,
  outlet,
  externalHref,
  formattedDate,
  onClickLocationTag,
  tags,
  labels,
  bgColor,
  labelTextColor,
  fewSpotsColor,
  ...props
}: SchedulableCardProps) => {
  const { t } = useTranslation('chargeable')
  const { query } = useRouter()
  const { widgetSlug, categorySlug } = query as {
    widgetSlug: string
    categorySlug: string
  }

  return (
    <Link
      key={schedulable.id}
      href={
        externalHref ??
        widgetRoutes.ACTIVITY.url({
          widgetSlug: widgetSlug as string,
          categorySlug: categorySlug as string,
          activitySlug: schedulable.slug as string,
          date: formattedDate
        })
      }
      passHref
      legacyBehavior
    >
      <Item
        key={schedulable.id}
        variant="card"
        title={t('common:view-item-name', {
          itemName: definedNotNull(schedulable.name())
        })}
        target={externalHref ? '_blank' : undefined}
        {...props}
      >
        <CoverImage
          height={250}
          src={schedulable.imageURL()}
          top={
            labels &&
            labels.map(label => (
              <StyledTag
                variant="icon"
                key={label.title}
                bgColor={bgColor}
                labelTextColor={labelTextColor}
              >
                <Text fontStyle="p3">{label.text}</Text>
              </StyledTag>
            ))
          }
        />
        <ItemText>
          <H4>{schedulable.name()}</H4>
        </ItemText>
        {tags && (
          <ItemText>
            <StyledTagsList>
              {tags
                .flatMap(label => {
                  return [
                    <StyledDivider key={label + '--divider'} />,
                    <StyledTagText
                      size="sm"
                      fontstyle="p3"
                      color="secondary"
                      key={label}
                    >
                      {label}
                    </StyledTagText>
                  ]
                })
                .slice(1)}
            </StyledTagsList>
          </ItemText>
        )}
        {schedulable.fewSpotsLeft() && (
          <ItemText>
            <StyledH5 color={fewSpotsColor}>
              {t('common:few-spots-left')}
            </StyledH5>
          </ItemText>
        )}
        <ItemBottom>
          <ItemText>
            <PurchasableCardPrice
              pence={schedulable.priceStartingFrom()}
              currency={outlet.currency()}
            />
          </ItemText>
          {schedulable?.addressSummaryLine() && (
            <LocationTag
              text={schedulable.addressSummaryLine()}
              onClick={onClickLocationTag}
            />
          )}
        </ItemBottom>
      </Item>
    </Link>
  )
}

export default ActivityCard

const StyledTagText = styled(Text)(({ theme }) => ({
  lineHeight: theme?.fontStyle.p4.lineHeight
}))
