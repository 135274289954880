import Tag from 'library/atoms/Tag'
import Icon from 'shared/library/atoms/Icon'
import Link from 'next/link'
import CoverImage from 'library/atoms/CoverImage'
import widgetRoutes from 'enums/routes'
import PurchasableCardPrice from 'library/molecules/PurchasableCardPrice'
import { Text, H5, H6 } from 'library/atoms/Typography'
import Item, { ItemText } from 'library/molecules/Item'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import styled from '@emotion/styled'
import { SchedulableCardProps } from '../SchedulableCard'
import getRentalDisplayPrice from 'helpers/getRentalDisplayPrice'
import LocationTag from 'library/molecules/LocationTag'
import Button from 'library/atoms/Button'
import Flex from 'shared/library/atoms/Flex'

const RentalCardShort = ({
  schedulable,
  outlet,
  externalHref,
  onClickLocationTag,
  ...props
}: SchedulableCardProps) => {
  const { t } = useTranslation('chargeable')
  const { query } = useRouter()
  const widgetSlug = query.widgetSlug

  /**
   * Only render the price if the data has been fetched. This card is also used on the
   * https://eola.co/search page and the price shouldn't be showed on this page.
   * */
  const hasPrice = schedulable.resource_requirements

  return (
    <StyledItem
      variant="card"
      title={t('common:view-item-name', {
        itemName: schedulable.name()
      })}
      target={externalHref ? '_blank' : undefined}
      {...props}
    >
      <ImageContainer>
        <CoverImage
          height={100}
          variant="thumb"
          src={schedulable.imageURL()}
          top={
            schedulable.members_only && (
              <Tag variant="icon">
                <Icon name="voucher" color="gold" size={20} mr={3} />
                <H6>{t('common:members-only')}</H6>
              </Tag>
            )
          }
        />
      </ImageContainer>
      <StyledItemText>
        <TopFlex justify-content="space-between">
          <div>
            <H5>{schedulable.name()}</H5>
            {schedulable.tagline() && (
              <StyledTagline color="secondary">
                {schedulable.tagline()}
              </StyledTagline>
            )}
          </div>
          {hasPrice && (
            <PurchasableCardPrice
              pence={getRentalDisplayPrice({
                minimumDuration: schedulable.minimum_duration,
                durationMins: schedulable.duration_mins,
                lowestResourceReqPrice: schedulable.lowestResourceReqPrice()
              })}
              currency={outlet.currency()}
            />
          )}
        </TopFlex>
        <BottomFlex>
          {schedulable.addressSummaryLine() && (
            <LocationTag
              text={schedulable.addressSummaryLine()}
              onClick={onClickLocationTag}
            />
          )}
          <Link
            key={schedulable.id}
            href={
              externalHref ??
              widgetRoutes.RENTAL.url({
                widgetSlug: widgetSlug as string,
                rentalSlug: schedulable.slug as string
              })
            }
            passHref
            target="_blank"
          >
            <StyledButton mt={3}>{t('common:book-now')}</StyledButton>
          </Link>
        </BottomFlex>
      </StyledItemText>
    </StyledItem>
  )
}

export default RentalCardShort

const TopFlex = styled(Flex)(({ theme }) => ({
  marginTop: theme.space[4],
  justifyContent: 'space-between'
}))

const ImageContainer = styled.div(({ theme }) => ({
  minWidth: '100px',
  display: 'none',
  marginLeft: theme.space[4],
  marginTop: theme.space[4],

  [theme.mediaQueries.sm]: {
    display: 'block'
  }
}))
const StyledTagline = styled(Text)(({ theme }) => ({
  ...theme.mixins.ellipsisLineClamp(3)
}))

const StyledItem = styled(Item)(({ theme }) => ({
  flexDirection: 'column',

  [theme.mediaQueries.sm]: {
    flexDirection: 'row'
  }
}))

const StyledItemText = styled(ItemText)(() => ({
  flexGrow: 3
}))

const BottomFlex = styled(Flex)(({ theme }) => ({
  gap: theme.space[4],
  flexDirection: 'column',
  justifyContent: 'space-between',

  [theme.mediaQueries.xs]: {
    flexDirection: 'row'
  }
}))

const StyledButton = styled(Button)(() => ({
  alignSelf: 'flex-end'
}))
