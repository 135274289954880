/**
 * For some locales the formatting of prices on our purchasable cards (in Rentals and ActivitiesList)
 * may not make sense. Locale codes in this enum override the layout to use a single translated string.
 */
export enum CARD_PRICE_FALLBACK_LOCALES {}

export interface CountryRegex {
  locale: RegExp
  countryCode: RegExp
}

export const PORTUGAL_REGEX: CountryRegex = {
  locale: /(pt-PT)/,
  countryCode: /(PT)/
}
