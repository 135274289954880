import { MouseEvent } from 'react'
import { SchedulablePresenter, OutletPresenter } from 'presenters'
import { definedNotNull } from 'shared/presenters/presenter'
import ActivityCard from '../ActivityCard'
import RentalCard from '../RentalCard'
import ActivityCardShort from '../ActivityCardShort'
import RentalCardShort from '../RentalCardShort'
import useTranslation from 'next-translate/useTranslation'
import styled from '@emotion/styled'
import Tag from 'library/atoms/Tag'
import {
  hexToRgb,
  calculateBrightness,
  ColorValueHex
} from 'shared/helpers/colors'
import { H5 } from 'library/atoms/Typography'
import { defaultTheme } from 'assets/theme/theme'

export interface SchedulableCardProps {
  schedulable: SchedulablePresenter
  outlet: OutletPresenter
  /**
   * It will use the schedulable data to determine this value if href is not declared.
   * This prop is useful if we are rendering the cards from outside the widget such as via the
   * page https://eola.co/search.
   */
  externalHref?: string
  /**
   * Takes a string to output a class. Related to emotion - It lets another component style it
   */
  className?: string
  /** Date selection */
  formattedDate?: string
  variant?: 'short'
  onClickLocationTag?: (e: MouseEvent<HTMLElement>) => void
  tags?: string[]
  labels?: {
    title: string
    active: boolean
    icon: string
    text: string
  }[]
  bgColor?: string
  labelTextColor?: string
  fewSpotsColor?: ColorValueHex
}

/**
 * Core component to faciltate the rendering of either the Activity or Rental card.
 * This is useful in particular because we can style.
 */
const SchedulableCard = ({
  schedulable,
  outlet,
  className,
  externalHref,
  formattedDate,
  variant,
  bgColor = defaultTheme.colors.pink,
  onClickLocationTag
}: SchedulableCardProps) => {
  const { t } = useTranslation('chargeable')

  const brightnessValue = calculateBrightness(hexToRgb(bgColor))
  // We use a threshold of 186 to decide the widget button text color
  // Personally I found this to be too high, so I've reduced it to 125 here
  const isBright = brightnessValue > 125
  const labelTextColor = isBright ? 'black' : 'white'
  const fewSpotsColor = isBright ? defaultTheme.colors.pink : bgColor

  const cards =
    variant === 'short'
      ? {
          activity: ActivityCardShort,
          rental: RentalCardShort
        }
      : {
          activity: ActivityCard,
          rental: RentalCard
        }

  const type = definedNotNull(
    schedulable.type
  ).toLocaleLowerCase() as keyof typeof cards
  const ComponentToRender = cards[type]
  const isRental = type === 'rental'

  const labels = [
    {
      title: 'available',
      active: schedulable.availableThisWeekend(),
      icon: 'ticket-star',
      text: t('common:available-this-weekend')
    },
    {
      title: 'popular',
      active: schedulable.popularity ? schedulable.popularity < 'Z' : false,
      icon: 'heart',
      text: t('common:popular')
    }
  ].filter(x => x.active)

  const tags = [
    isRental ? t('common:rental') : '',
    isRental ? '' : schedulable.formatDurationText(t, 'short'),
    isRental
      ? ''
      : t('maximum-people', {
          count: definedNotNull(schedulable.maximum_capacity)
        }),
    schedulable.members_only ? t('common:members-only') : '',
    schedulable.groupDiscountsAvailable() ? t('common:group-discounts') : ''
  ].filter(x => x !== '')

  return (
    <ComponentToRender
      schedulable={schedulable}
      outlet={outlet}
      className={className}
      externalHref={externalHref}
      formattedDate={formattedDate}
      onClickLocationTag={onClickLocationTag}
      labels={labels}
      tags={tags}
      bgColor={bgColor}
      labelTextColor={labelTextColor}
      fewSpotsColor={fewSpotsColor as ColorValueHex}
    />
  )
}

export default SchedulableCard

export const StyledTagsList = styled.ul(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItem: 'center',
  padding: 0,
  listStyleType: 'none',
  margin: 0,
  gap: theme.space[1]
}))

export const StyledDivider = styled.hr(({ theme }) => ({
  background: theme.colors.icon.grey,
  height: 3,
  width: 3,
  margin: `auto 1px`,
  border: 'none',
  borderRadius: theme.radii[3]
}))

export const StyledTag = styled(Tag)(({ theme }) => ({
  padding: '4px 8px',
  marginBottom: theme.space[1],
  width: 'fit-content',
  alignSelf: 'flex-end'
}))

export const StyledH5 = styled(H5)(({ color }) => ({
  color: color
}))
