import React from 'react'
import styled from '@emotion/styled'
import { Text } from 'library/atoms/Typography'
import money, { MoneyProps } from 'shared/helpers/money'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { CARD_PRICE_FALLBACK_LOCALES } from 'shared/enums/locale'

export interface PurchasableCardPriceProps
  extends Omit<MoneyProps, 'wholeNumber'> {
  // allows the parent to force use of the fallback string
  fallbackLayout?: boolean
  displayPerPerson?: boolean
}

/**
 * Displays a translated price in the form of "From ⏎ $Xpp" for purchasable cards
 * Offers the capability to fallback to a single translated string for languages
 * that may not support the layout (see CARD_PRICE_FALLBACK_LOCALES)
 */
const PurchasableCardPrice = ({
  pence,
  currency,
  fallbackLayout = false,
  displayPerPerson = false
}: PurchasableCardPriceProps) => {
  const { locale } = useRouter() || {}
  const { t } = useTranslation('chargeable')

  /**
   * We want to show decimals on card price only if the price is not a whole number
   * e.g £7.50pp and £8pp.
   * We feed in priceInPoundsIsWholeNumber to "wholeNumber" in money props below.
   */
  const priceInPoundsIsWholeNumber = (pence / 100) % 1 === 0

  const price = money({
    pence,
    currency,
    locale,
    wholeNumber: priceInPoundsIsWholeNumber
  })

  const count = Math.ceil(pence / 100)
  const useFallbackString =
    (locale && Object.values(CARD_PRICE_FALLBACK_LOCALES).includes(locale)) ||
    fallbackLayout

  return (
    <StyledPurchasableCardPrice>
      {useFallbackString && displayPerPerson ? (
        <Text fontStyle="h5" color="primary">
          {t('from-x-price-per-person', { count, price })}
        </Text>
      ) : (
        <>
          <Text fontstyle="p3" size="sm" color="secondary">
            {t('from-price')}
          </Text>
          {displayPerPerson ? (
            <Text ml={1} fontStyle="h5" color="primary">
              {t('x-price-per-person', { count, price })}
            </Text>
          ) : (
            <Text ml={1} fontStyle="h5" color="primary">
              {price}
            </Text>
          )}
        </>
      )}
    </StyledPurchasableCardPrice>
  )
}

export default PurchasableCardPrice

const StyledPurchasableCardPrice = styled.div(() => ({
  // more padding left to match design
  display: 'flex',
  alignItems: 'baseline'
}))
